var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup-legal-contact-person"},[_c('div',{staticClass:"signup-legal-contact-form"},[_c('SignupForm',[_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.contact_person_fullname'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"name","name":"name","required":"","type":"text","error":(_vm.$v.contactPersonFullName.$dirty &&
              !_vm.$v.contactPersonFullName.required) ||
            (_vm.$v.contactPersonFullName.$dirty &&
              _vm.$v.contactPersonFullName.$invalid),"error-txt":_vm.contactPersonFullNameErrorText},on:{"isFocus":_vm.clearContactPersonFullNameField,"blur":function($event){return _vm.$v.contactPersonFullName.$touch()},"input":_vm.inputFullNameHandler},model:{value:(_vm.contactPersonFullName),callback:function ($$v) {_vm.contactPersonFullName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"contactPersonFullName"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"position"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.contact_person_position'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"position","name":"position","required":"","type":"text","error":(_vm.$v.contactPersonPosition.$dirty &&
              !_vm.$v.contactPersonPosition.required) ||
            (_vm.$v.contactPersonPosition.$dirty &&
              _vm.$v.contactPersonPosition.$invalid),"error-txt":_vm.contactPersonPositionErrorText},on:{"isFocus":_vm.clearContactPersonPositionField,"blur":function($event){return _vm.$v.contactPersonPosition.$touch()}},model:{value:(_vm.contactPersonPosition),callback:function ($$v) {_vm.contactPersonPosition=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"contactPersonPosition"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.contact_person_email'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"email","type":"email","name":"email","required":"","error":(_vm.$v.contactPersonEmail.$dirty &&
              !_vm.$v.contactPersonEmail.required) ||
            (_vm.$v.contactPersonEmail.$dirty &&
              _vm.$v.contactPersonEmail.$invalid) ||
            !!_vm.serverError,"error-txt":_vm.contactPersonEmailErrorText},on:{"isFocus":_vm.clearContactPersonEmailField,"blur":function($event){return _vm.$v.contactPersonEmail.$touch()}},model:{value:(_vm.contactPersonEmail),callback:function ($$v) {_vm.contactPersonEmail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"contactPersonEmail"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"phone"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.contact_person_phone'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"phone","type":"text","name":"phone","required":"","placeholder":"+ __ ___ ___ __ __","error":(_vm.$v.contactPersonPhone.$dirty &&
              !_vm.$v.contactPersonPhone.required) ||
            (_vm.$v.contactPersonPhone.$dirty && _vm.$v.contactPersonPhone.$invalid),"error-txt":_vm.contactPersonPhoneErrorText},on:{"isFocus":_vm.clearContactPersonPhoneField,"blur":function($event){return _vm.$v.contactPersonPhone.$touch()}},model:{value:(_vm.contactPersonPhone),callback:function ($$v) {_vm.contactPersonPhone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"contactPersonPhone"}})],1),_c('div',{staticClass:"signup-form__group-item checkbox"},[_c('CheckboxField',{attrs:{"error":_vm.$v.contactPersonAgreement.$dirty &&
            !_vm.$v.contactPersonAgreement.required,"error-txt":_vm.contactPersonAgreementErrorText},model:{value:(_vm.$v.contactPersonAgreement.$model),callback:function ($$v) {_vm.$set(_vm.$v.contactPersonAgreement, "$model", $$v)},expression:"$v.contactPersonAgreement.$model"}})],1)])],1),_c('SignupControlButtons',{attrs:{"back-button-text":_vm.$t('signup.button.back'),"go-button-text":_vm.$t('signup.button.registration'),"loading-go-btn":_vm.loadingSubmitBtn},on:{"go-back":_vm.goBack,"go-on":_vm.submitHandler}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }