import {
  required,
  minLength,
  email,
  numeric,
  helpers,
} from 'vuelidate/lib/validators';

import VueI18n from '@/i18n';

const fullName = helpers.regex(
  'alpha',
  /^([A-Za-z-]|[а-щА-ЩЬьЪъЫыЭэЮюЯяЇїІіЄєҐґёЁ'-])+\s(([A-Za-z-]|[а-щА-ЩЬьЪъЫыЭэЮюЯяЇїІіЄєҐґёЁ'-])(\s)?)+$/
);
const phone = helpers.regex('number', /^\+[0-9]+$/);

const isCheckedAgreement = (value) => !!value;

export default {
  validations: {
    contactPersonFullName: {
      required,
      alphaCheck: fullName,
    },
    contactPersonPosition: {
      required,
      minLength: minLength(2),
    },
    contactPersonEmail: {
      required,
      email,
    },
    contactPersonPhone: {
      required,
      numeric: phone,
      minLength: minLength(11),
    },
    contactPersonAgreement: {
      required: isCheckedAgreement,
    },
  },
  computed: {
    contactPersonFullNameErrorText() {
      if (!this.$v.contactPersonFullName.required) {
        return VueI18n.t(
          'signup.legal.validation.contact_person_fullname.empty'
        );
      }
      if (!this.$v.contactPersonFullName.alphaCheck) {
        return VueI18n.t(
          'signup.legal.validation.contact_person_fullname.alpha_check'
        );
      }
    },
    contactPersonPositionErrorText() {
      if (!this.$v.contactPersonPosition.required) {
        return VueI18n.t(
          'signup.legal.validation.contact_person_position.empty'
        );
      }
      if (!this.$v.contactPersonPosition.minLength) {
        return VueI18n.t(
          'signup.legal.validation.contact_person_position.min_length'
        );
      }
    },
    contactPersonEmailErrorText() {
      if (!this.$v.contactPersonEmail.required) {
        return VueI18n.t('signup.legal.validation.contact_person_email.empty');
      }
      if (!this.$v.contactPersonEmail.email) {
        return VueI18n.t(
          'signup.legal.validation.contact_person_email.invalid'
        );
      }
      if (this.serverError) {
        return VueI18n.t(
          'signup.legal.validation.contact_person_email.existing'
        );
      }
    },
    contactPersonPhoneErrorText() {
      if (!this.$v.contactPersonPhone.required) {
        return VueI18n.t('signup.legal.validation.contact_person_phone.empty');
      }
      if (!this.$v.contactPersonPhone.numeric) {
        return VueI18n.t(
          'signup.legal.validation.contact_person_phone.numeric'
        );
      }
      if (!this.$v.contactPersonPhone.minLength) {
        return VueI18n.t(
          'signup.legal.validation.contact_person_phone.min_length'
        );
      }
    },
    contactPersonAgreementErrorText() {
      if (!this.$v.contactPersonAgreement.required) {
        return VueI18n.t(
          'signup.legal.validation.contact_person_agreement.empty'
        );
      }
    },
  },
};
