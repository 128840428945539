<template>
  <div class="signup-legal-contact-person">
    <div class="signup-legal-contact-form">
      <SignupForm>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="name">
            {{ $t('signup.labels.legal.contact_person_fullname') }}
          </label>
          <TextField
            id="name"
            v-model.trim="contactPersonFullName"
            name="name"
            required
            class="signup-form__input"
            type="text"
            :error="
              ($v.contactPersonFullName.$dirty &&
                !$v.contactPersonFullName.required) ||
              ($v.contactPersonFullName.$dirty &&
                $v.contactPersonFullName.$invalid)
            "
            :error-txt="contactPersonFullNameErrorText"
            @isFocus="clearContactPersonFullNameField"
            @blur="$v.contactPersonFullName.$touch()"
            @input="inputFullNameHandler"
          />
        </div>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="position">
            {{ $t('signup.labels.legal.contact_person_position') }}
          </label>
          <TextField
            id="position"
            v-model.trim="contactPersonPosition"
            name="position"
            required
            class="signup-form__input"
            type="text"
            :error="
              ($v.contactPersonPosition.$dirty &&
                !$v.contactPersonPosition.required) ||
              ($v.contactPersonPosition.$dirty &&
                $v.contactPersonPosition.$invalid)
            "
            :error-txt="contactPersonPositionErrorText"
            @isFocus="clearContactPersonPositionField"
            @blur="$v.contactPersonPosition.$touch()"
          />
        </div>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="email">
            {{ $t('signup.labels.legal.contact_person_email') }}
          </label>
          <TextField
            id="email"
            v-model.trim="contactPersonEmail"
            type="email"
            name="email"
            required
            class="signup-form__input"
            :error="
              ($v.contactPersonEmail.$dirty &&
                !$v.contactPersonEmail.required) ||
              ($v.contactPersonEmail.$dirty &&
                $v.contactPersonEmail.$invalid) ||
              !!serverError
            "
            :error-txt="contactPersonEmailErrorText"
            @isFocus="clearContactPersonEmailField"
            @blur="$v.contactPersonEmail.$touch()"
          />
        </div>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="phone">
            {{ $t('signup.labels.legal.contact_person_phone') }}
          </label>
          <TextField
            id="phone"
            v-model.trim="contactPersonPhone"
            type="text"
            name="phone"
            required
            placeholder="+ __ ___ ___ __ __"
            class="signup-form__input"
            :error="
              ($v.contactPersonPhone.$dirty &&
                !$v.contactPersonPhone.required) ||
              ($v.contactPersonPhone.$dirty && $v.contactPersonPhone.$invalid)
            "
            :error-txt="contactPersonPhoneErrorText"
            @isFocus="clearContactPersonPhoneField"
            @blur="$v.contactPersonPhone.$touch()"
          />
        </div>
        <div class="signup-form__group-item checkbox">
          <CheckboxField
            v-model="$v.contactPersonAgreement.$model"
            :error="
              $v.contactPersonAgreement.$dirty &&
              !$v.contactPersonAgreement.required
            "
            :error-txt="contactPersonAgreementErrorText"
          />
        </div>
      </SignupForm>
    </div>
    <SignupControlButtons
      :back-button-text="$t('signup.button.back')"
      :go-button-text="$t('signup.button.registration')"
      :loading-go-btn="loadingSubmitBtn"
      @go-back="goBack"
      @go-on="submitHandler"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import validate from '@/mixins/validation/signup/legalContactPerson';

import SignupControlButtons from '@/components/Signup/SignupControlButtons/SignupControlButtons.vue';
import SignupForm from '@/components/Signup/SignupForm.vue';
import TextField from '@/elements/Inputs/TextField.vue';
import CheckboxField from '@/elements/Inputs/CheckboxField.vue';

export default {
  components: {
    SignupControlButtons,
    SignupForm,
    TextField,
    CheckboxField,
  },
  mixins: [validate],
  data() {
    return {
      contactPersonFullName: '',
      contactPersonPosition: '',
      contactPersonEmail: '',
      contactPersonPhone: '',
      contactPersonAgreement: false,

      loadingSubmitBtn: false,
      serverError: null,
    };
  },
  computed: {
    ...mapGetters(['getLegalPersonInfo']),
    contactPersonFullNameArray() {
      return this.contactPersonFullName.split(' ');
    },
    contactPersonLastName() {
      return this.contactPersonFullNameArray[0];
    },
    contactPersonFirstName() {
      return this.contactPersonFullNameArray[1];
    },
    contactPersonSecondName() {
      return this.contactPersonFullNameArray[2];
    },
    contactPersonInfo() {
      const user = {
        last_name: this.contactPersonLastName,
        first_name: this.contactPersonFirstName,
        position: this.contactPersonPosition,
        email: this.contactPersonEmail,
        telephone_number: this.contactPersonPhone,
      };

      if (this.contactPersonSecondName) {
        user.second_name = this.contactPersonSecondName;
      }

      return user;
    },
    legalPersonFullInfo() {
      return {
        ...this.getLegalPersonInfo,
        ...this.contactPersonInfo,
      };
    },
  },
  methods: {
    clearContactPersonFullNameField() {
      this.$v.contactPersonFullName.$reset();
    },
    clearContactPersonPositionField() {
      this.$v.contactPersonPosition.$reset();
    },
    clearContactPersonEmailField() {
      this.$v.contactPersonEmail.$reset();
      this.serverError = null;
    },
    clearContactPersonPhoneField() {
      this.$v.contactPersonPhone.$reset();
    },
    inputFullNameHandler(fullName) {
      this.contactPersonFullName = fullName
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    goBack() {
      this.$router.push({ name: 'SignupLegalPerson' });
    },
    async submitHandler() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loadingSubmitBtn = true;
      this.serverError = await this.$store.dispatch(
        'signupLegalPerson',
        this.legalPersonFullInfo
      );
      this.loadingSubmitBtn = false;
    },
  },
};
</script>

<style lang="sass">
.signup-legal-contact-form
  padding-top: 48px

  @media screen and (max-width: 1199px)
    padding-top: 40px

  @media screen and (max-width: 600px)
    padding-top: 32px
</style>
